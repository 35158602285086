import { Extension } from '@tiptap/vue-2'
import { Color } from '@tiptap/extension-color'
import { Highlight } from '@tiptap/extension-highlight'
import { getColors } from '@utils'

export interface TextColorOptions {
    colors: string[];
    text: boolean;
    highlight: boolean;
}

/**
 * Legacy background color extension
 *
 * ? We add this extension for backwards compatibility with <span /> tags and inline backgroundColor
 * ? This is just for TipTap to understand how to render them any new highlights will be have
 * ? <mark /> applied to them.
 */
const BackgroundColorLegacy = Extension.create({
    name: 'backgroundColor',

    addGlobalAttributes() {
        return [
            {
                types: ['textStyle'],
                attributes: {
                    backgroundColor: {
                        default: null,
                        parseHTML: element => element.style.backgroundColor?.replace(/['"]+/g, ''),
                        renderHTML: attributes => {
                            if (!attributes.backgroundColor) {
                                return {}
                            }

                            return {
                                style: `background-color: ${attributes.backgroundColor}`,
                            }
                        },
                    },
                },
            },
        ]
    },
})

/**
 * Text color plugin
 *
 * Combination of Text color and text backgrounds
 */
const TextColor = Extension.create<TextColorOptions>({
    name: 'textColor',

    addOptions() {
        return {
            colors: getColors(),
            text: true,
            highlight: true,
        }
    },

    addExtensions() {
        const extensions = []

        if (this.options.text)
            extensions.push(Color)

        if (this.options.highlight)
            extensions.push(
                BackgroundColorLegacy,
                Highlight.configure({ multicolor: true }),
            )

        return extensions
    },
})

export default TextColor