<template>
    <tiptap-dropdown
        #default="{ textColor, hide }"
        extension="textColor"
        icon="palette"
        label="Color"
    >
        <b-tabs
            class="pr-3 pl-3 pb-2"
            small
            lazy
            fill
        >
            <b-tab
                v-if="textColor.text"
                :title="$t('TIPTAP.TEXT_COLOR')"
            >
                <color-select-field
                    class="mb-0"
                    type="hex"
                    :options="textColor.colors"
                    clearable
                    @input="onCustomColorSelected($event, hide)"
                />
            </b-tab>
            <b-tab
                v-if="textColor.highlight"
                :title="$t('TIPTAP.HIGHLIGHT')"
            >
                <color-select-field
                    class="mb-0"
                    type="hex"
                    :options="textColor.colors"
                    clearable
                    @input="onCustomBackgroundColorSelected($event, hide)"
                />
            </b-tab>
        </b-tabs>
    </tiptap-dropdown>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { BTabs, BTab } from 'bootstrap-vue'
    import  ColorSelectField from '@common/Forms/ColorSelectField.vue'
    import { Inject } from './TiptapEditorProvider.vue'
    import TiptapDropdown from './TiptapDropdown.vue'

    interface Refs {
        $refs: {
            customColor: HTMLInputElement
            customBackgroundColor: HTMLInputElement
        }
    }

    export default (Vue as VueConstructor<Vue & Refs & Inject>).extend({
        components: {
            BTabs,
            BTab,
            TiptapDropdown,
            ColorSelectField,
        },

        inject: ['editor', 'extensions'],

        methods: {
            onCustomColorSelected(value: string | null, hide: () => void) {
                if (!value) {
                    this.editor.chain().unsetColor()
                        .focus()
                        .run()

                    return hide()
                }

                this.editor.chain()
                    .setColor(value)
                    .focus()
                    .run()

                hide()
            },

            onCustomBackgroundColorSelected(value: string | null, hide: () => void) {
                if (!value) {
                    this.editor.chain().unsetHighlight()
                        .focus()
                        .run()

                    return hide()
                }

                this.editor.chain()
                    .setHighlight({ color: value })
                    .focus()
                    .run()

                hide()
            },
        },
    })
</script>

<style lang="scss" scoped>
    ::v-deep(.color-select-field){
        width: 18.5rem;
    }
</style>