<template>
    <div
        class="avatar"
        :class="baseClasses"
        :style="baseStyles"
    >
        <svg
            v-if="initials"
            viewBox="0 0 40 40"
            class="w-100"
            :class="public ? 'bg-secondary' : 'bg-primary'"
        >
            <text
                v-if="!image"
                text-anchor="middle"
                y="50%"
                x="50%"
                dy="0.35em"
                class="fill-primary-txt font-weight-bold"
                v-text="initials"
            />
        </svg>
        <img
            v-if="image"
            :src="image"
            :alt="initials"
        >
        <span v-if="!image && !initials">
            <i class="fas fa-user text-gray-light" />
        </span>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { buildFullPath } from '@common/Resources/utils'

    /**
     * Avatar displays a circular image, perfect for displaying a user image.
     *
     * When no value is provided you will get a fallback user icon
     */
    export default Vue.extend({
        props: {
            /**
             * The key to the image, can be a s3 key or a full image path
             */
            value: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * (optional) User full name, when user has no image we can display their initials
             */
            userName: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Avatar size
             * @values xs, sm, md, lg, xl,
             */
            size: {
                type: String as PropType<TShirtSizes>,
                default: 'md',
            },
            /**
             * Avatar size in css units
             * - When you need a specific size on your avatar fx 10rem, you can pass that in here
             *   this will overwrite any `size` prop selection you already have set
             */
            sizeUnit: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Get src key from public bucket of s3
             */
            public: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                image: null as string | null,
            }
        },

        computed: {
            baseClasses(): any[] {
                return [
                    `avatar-${this.size}`,
                ]
            },

            baseStyles(): Partial<CSSStyleDeclaration> {
                return {
                    ...(this.sizeUnit) && {
                        width: this.sizeUnit,
                        height: this.sizeUnit,
                    },
                }
            },

            initials(): string | null {
                if (!this.userName) return null

                const names = this.userName.split(' ')
                let initials = names[0].substring(0, 1).toUpperCase()

                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase()
                }

                return initials
            },
        },

        watch: {
            value: {
                handler(value, oldValue): void {
                    if (!oldValue || value !== oldValue)
                        this.image =  buildFullPath(value, this.public)
                },
                immediate: true,
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .d-flex, .row {
        .avatar {
            flex-shrink: 0;
        }
    }
</style>

<docs>
**Output:**
```vue
    <!-- Fallback avatar -->
    <avatar />
    <!-- External image avatar -->
    <avatar value="https://i.pinimg.com/236x/ee/f0/5a/eef05acc584c8f2a37c6bc481d8e627a.jpg" />
    <!-- Avatar with initials -->
    <avatar user-name="Bruce Wayne" />

    <p>Sizes</p>
    <avatar user-name="Ayten Pruschke" size="xs" />
    <avatar user-name="Allison Fowler" size="sm" />
    <avatar user-name="Britt" size="md" />
    <avatar user-name="Jesús Nicolás Valdés Casanovas" size="lg" />
    <avatar user-name="Thérèse Rousset-Lebrun" size="xl" />
```
</docs>
